<template>
  <div class="anual-gr">
    <v-row dense>
      <v-col cols="12" md="4" sm="12">
        <v-select
          outlined
          rounded
          dense
          v-model="year"
          color="primary"
          :items="years"
          item-color="primary"
          :menu-props="{ maxHeight: '400' }"
          label="Año"
          item-text="year"
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <figure :class="`highcharts-figure ${_id}`">
      <div :id="_id"></div>
    </figure>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["_id"],
  data: () => ({
    year: {
      year: moment().format("YYYY"),
      date: {
        inicio: moment(moment().format("YYYY"), "YYYY")
          .startOf("year")
          .format("YYYY-MM-DD"),
        fin: moment(moment().format("YYYY"), "YYYY")
          .endOf("year")
          .format("YYYY-MM-DD"),
      },
    },
    options: {
      chart: {
        type: "column",
        styledMode: true,
      },
      title: {
        text: "Facturación mensual",
      },
      subtitle: {
        text:
          "La información aquí mostrada, tiene encuenta tanto las facturas de credito como las de contado, excluyendo notas credito.",
      },
      yAxis: [
        {
          className: "highcharts-color-0",
          title: {
            text: "Facturas",
          },
        },
        {
          className: "highcharts-color-1",
          opposite: true,
          title: {
            text: "Total",
          },
        },
      ],
      xAxis: [
        {
          categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
        },
      ],

      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: [
        {
          name: "Facturas",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Venta neta",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          yAxis: 1,
        },
      ],
    },
  }),
  methods: {
    loadFacturasAnuales() {
      const body = {
        route: "/movimientos_anuales",
        params: {
          date: JSON.stringify(this.year.date),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          // Cantidad
          this.options.series[0].data[0] = response.data.data["01"].cantidad;
          this.options.series[0].data[1] = response.data.data["02"].cantidad;
          this.options.series[0].data[2] = response.data.data["03"].cantidad;
          this.options.series[0].data[3] = response.data.data["04"].cantidad;
          this.options.series[0].data[4] = response.data.data["05"].cantidad;
          this.options.series[0].data[5] = response.data.data["06"].cantidad;
          this.options.series[0].data[6] = response.data.data["07"].cantidad;
          this.options.series[0].data[7] = response.data.data["08"].cantidad;
          this.options.series[0].data[8] = response.data.data["09"].cantidad;
          this.options.series[0].data[9] = response.data.data["10"].cantidad;
          this.options.series[0].data[10] = response.data.data["11"].cantidad;
          this.options.series[0].data[11] = response.data.data["12"].cantidad;
          //   Total
          this.options.series[1].data[0] = response.data.data["01"].total;
          this.options.series[1].data[1] = response.data.data["02"].total;
          this.options.series[1].data[2] = response.data.data["03"].total;
          this.options.series[1].data[3] = response.data.data["04"].total;
          this.options.series[1].data[4] = response.data.data["05"].total;
          this.options.series[1].data[5] = response.data.data["06"].total;
          this.options.series[1].data[6] = response.data.data["07"].total;
          this.options.series[1].data[7] = response.data.data["08"].total;
          this.options.series[1].data[8] = response.data.data["09"].total;
          this.options.series[1].data[9] = response.data.data["10"].total;
          this.options.series[1].data[10] = response.data.data["11"].total;
          this.options.series[1].data[11] = response.data.data["12"].total;
          Highcharts.chart(this._id, this.options);
        }
      });
    },
  },
  created() {
    this.loadFacturasAnuales();
  },
  mounted() {
    Highcharts.chart(this._id, this.options);
  },
  watch: {
    year() {
      this.loadFacturasAnuales();
      return this.year;
    },
  },
  computed: {
    years() {
      const first = moment(moment("2018", "YYYY"));
      const actual = moment();
      const diff = actual.diff(first, "years");
      let fechas = [
        {
          year: "2018",
          date: {
            inicio: moment("2018", "YYYY")
              .startOf("year")
              .format("YYYY-MM-DD"),
            fin: moment("2018", "YYYY")
              .endOf("year")
              .format("YYYY-MM-DD"),
          },
        },
      ];
      for (let i = 0; i < diff; i++) {
        let add_fecha = {
          year: first.add(1, "year").format("YYYY"),
          date: {
            inicio: moment(first, "YYYY")
              .startOf("year")
              .format("YYYY-MM-DD"),
            fin: moment(first, "YYYY")
              .endOf("year")
              .format("YYYY-MM-DD"),
          },
        };
        fechas.push(add_fecha);
      }
      return fechas;
    },
  },
};
</script>

<style scoped>
.chart-anual {
  max-width: 900px;
}
</style>

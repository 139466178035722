<template>
  <div class="gr-segmento">
    <figure :class="`highcharts-figure ${_id}`">
      <div :id="_id"></div>
    </figure>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["_id"],
  data: () => ({
    options: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {text:"Rango de edad"},
      subtitle: {text:"Total clientes: 0"},
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.y}",
          },
          showInLegend: true
        },
      },
      series: [
        {
          name: "Clientes",
          colorByPoint: true,
          data: [
            {
              name: "Niños (0-11)",
              y: 0,
              sliced: true,
              selected: true,
            },
            {
              name: "Adolescentes (12-18)",
              y: 0,
            },
            {
              name: "Jovenes (19-26)",
              y: 0,
            },
            {
              name: "Adulto (27-50)",
              y: 0,
            },
            {
              name: "Adulto Mayor (50+)",
              y: 0,
            },
          ],
        },
      ],
    },
  }),
  methods: {
    loadSegmento() {
      const body = {
        route: "/segmento",
      };
      this.$store.dispatch("axios_get", body).then(response=> {
        if(response.data.success){
          const data = response.data.data
          const total = Object.values(data).reduce((index, item) =>{
            return index + parseInt(item)
          },0)
          this.options.subtitle.text = `Total clientes: ${total}`

          this.options.series[0].data[0].y = data.niños
          this.options.series[0].data[1].y = data.adolecentes
          this.options.series[0].data[2].y = data.jovenes
          this.options.series[0].data[3].y = data.adultos
          this.options.series[0].data[4].y = data.adulto_mayor
          this.options.plotOptions.pie.dataLabels.enabled = true

          Highcharts.chart(this._id, this.options);
        }
      })
    },
  },
  created() {
    this.loadSegmento();
  },
  mounted() {
    Highcharts.chart(this._id, this.options);
  },
};
</script>

<style scoped>
.chart-seg {
  max-width: 800px;
}

</style>

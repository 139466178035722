<template>
  <div class="facturas-gr">
    <v-row dense>
      <v-col cols="12" md="4" sm="12">
        <v-menu
          v-model="menu_1"
          :close-on-content-click="false"
          :nudge-right="20"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              outlined
              rounded
              dense
              label="Mes"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            scrollable
            v-model="date"
            locale="es-co"
            color="primary"
            type="month"
            @input="menu_1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <figure :class="`highcharts-figure ${_id}`">
      <div :id="_id"></div>
    </figure>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["_id"],
  data: () => ({
    menu_1: false,
    date: moment().format("YYYY-MM"),
    options: {
      chart: {
        type: "column",
        styledMode: false,
      },
      title: {
        text: "Movimientos semanales",
      },
      yAxis: [
        {
          title: {
            text: "Facturas",
          },
        },
        {
          opposite: true,
          title: {
            text: "Notas credito",
          },
        },
      ],
      xAxis: [
        {
          categories: ["L", "M", "M", "J", "V", "S", "D"],
        },
      ],

      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: [
        {
          color: '#7cb5ec',
          name: "Facturas",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Notas Credito",
          color: '#f57878', 
          data: [0, 0, 0, 0, 0, 0, 0],
          yAxis: 1,
        },
      ],
    },
  }),
  methods: {
    loadFacturasDia() {
      const date = {
        inicio: moment(this.date, "YYYY-MM")
          .startOf("month")
          .format("YYYY-MM-DD"),
        fin: moment(this.date, "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const body = {
        route: "/movimientos_semanales",
        params: {
          date: JSON.stringify(date),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          let facturas = [0, 0, 0, 0, 0, 0, 0];
          let notas = [0, 0, 0, 0, 0, 0, 0];
          response.data.data.facturas.forEach((fecha) => {
            switch (moment(fecha.fecha).isoWeekday()) {
              case 1:
                facturas[0] += parseInt(fecha.cantidad);
                break;
              case 2:
                facturas[1] += parseInt(fecha.cantidad);
                break;
              case 3:
                facturas[2] += parseInt(fecha.cantidad);
                break;
              case 4:
                facturas[3] += parseInt(fecha.cantidad);
                break;
              case 5:
                facturas[4] += parseInt(fecha.cantidad);
                break;
              case 6:
                facturas[5] += parseInt(fecha.cantidad);
                break;
              default:
                facturas[6] += parseInt(fecha.cantidad);
                break;
            }
          });

          response.data.data.notas.forEach((fecha) => {
            switch (moment(fecha.fecha).isoWeekday()) {
              case 1:
                notas[0] += parseInt(fecha.cantidad);
                break;
              case 2:
                notas[1] += parseInt(fecha.cantidad);
                break;
              case 3:
                notas[2] += parseInt(fecha.cantidad);
                break;
              case 4:
                notas[3] += parseInt(fecha.cantidad);
                break;
              case 5:
                notas[4] += parseInt(fecha.cantidad);
                break;
              case 6:
                notas[5] += parseInt(fecha.cantidad);
                break;
              default:
                notas[6] += parseInt(fecha.cantidad);
                break;
            }
          });

          this.options.series[0].data = facturas;
          this.options.series[1].data = notas;
          Highcharts.chart(this._id, this.options);
        }
      });
    },
  },
  created() {
    this.loadFacturasDia();
  },
  mounted() {
    Highcharts.chart(this._id, this.options);
  },
  watch: {
    date() {
      this.loadFacturasDia();
      return this.date;
    },
  },
};
</script>

<style scoped>
.chart-fact {
  max-width: 800px;
}

</style>

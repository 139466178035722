<template>
  <div class="servicios-gr">
    <v-row dense>
      <v-col cols="12" md="6" sm="12">
        <v-menu
          v-model="menu_1"
          :close-on-content-click="false"
          :nudge-right="20"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              outlined
              rounded
              dense
              label="Mes"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            scrollable
            v-model="date"
            locale="es-co"
            color="primary"
            type="month"
            @input="menu_1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <figure :class="`highcharts-figure ${_id}`">
      <div :id="_id"></div>
    </figure>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["_id"],
  data: () => ({
    menu_1: false,
    date: moment().format("YYYY-MM"),
    options: {
      chart: {
        type: "column",
        styledMode: true,
      },
      title: {
        text: "Venta de servicios por semana",
      },
      subtitle: {
        text:
          "",
      },
      yAxis: [
        {
          className: "highcharts-color-0",
          title: {
            text: "Cantidad",
          },
        },
        {
          className: "highcharts-color-1",
          opposite: true,
          title: {
            text: "Total",
          },
        },
      ],
      xAxis: [
        {
          categories: ["L", "M", "M", "J", "V", "S", "D"],
        },
      ],

      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: [
        {
          name: "Cantidad",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Total",
          data: [0, 0, 0, 0, 0, 0, 0],
          yAxis: 1,
        },
      ],
    },
  }),
  methods: {
    loadServiciosDia() {
      const date = {
        inicio: moment(this.date, "YYYY-MM")
          .startOf("month")
          .format("YYYY-MM-DD"),
        fin: moment(this.date, "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const body = {
        route: "/servicios_dia",
        params: {
          date: JSON.stringify(date),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          let cantidades = [0, 0, 0, 0, 0, 0, 0];
          let total = [0, 0, 0, 0, 0, 0, 0];
          response.data.data.forEach((fecha) => {
            let data = {
              cantidad: parseFloat(fecha.cantidad),
              total: parseFloat(fecha.total),
            };
            switch (moment(fecha.fecha).isoWeekday()) {
              case 1:
                cantidades[0] += data.cantidad;
                total[0] += data.total;
                break;
              case 2:
                cantidades[1] += data.cantidad;
                total[1] += data.total;
                break;
              case 3:
                cantidades[2] += data.cantidad;
                total[2] += data.total;
                break;
              case 4:
                cantidades[3] += data.cantidad;
                total[3] += data.total;
                break;
              case 5:
                cantidades[4] += data.cantidad;
                total[4] += data.total;
                break;
              case 6:
                cantidades[5] += data.cantidad;
                total[5] += data.total;
                break;
              default:
                cantidades[6] += data.cantidad;
                total[6] += data.total;
                break;
            }
          });

          this.options.series[0].data = cantidades;
          this.options.series[1].data = total;
          Highcharts.chart(this._id, this.options);
        }
      });
    },
  },
  created() {
    this.loadServiciosDia();
  },
  mounted() {
    Highcharts.chart(this._id, this.options);
  },
  watch: {
    date() {
      this.loadServiciosDia();
      return this.date;
    },
  },
};
</script>

<template>
  <div class="profesionales-gr">
    <v-row dense>
      <v-col cols="12" md="6" sm="12">
        <v-menu
          v-model="menu_1"
          :close-on-content-click="false"
          :nudge-right="20"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              outlined
              rounded
              dense
              label="Mes"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            scrollable
            v-model="date"
            locale="es-co"
            color="primary"
            type="month"
            @input="menu_1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <figure :class="`highcharts-figure ${_id}`">
      <div :id="_id"></div>
    </figure>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["_id"],
  data: () => ({
    menu_1: false,
    date: moment().format("YYYY-MM"),
    options: {
      chart: {
        type: "column",
      },
      title: {
        text: "Servicios por profesional",
      },
      subtitle: {
        text: "",
      },
      yAxis: [
        {
          title: {
            text: "Cantidad",
          },
        },
        {
          opposite: true,
          title: {
            text: "Total",
          },
        },
      ],
      xAxis: {
        type: "category",
      },

      plotOptions: {
        column: {
          borderRadius: 5,
        },
      },
      series: [
        {
          name: "Servicios",
          data: [
            {
              name: "Profesional",
              y: 0,
            },
          ],
        },
        {
          name: "Total",
          yAxis: 1,
          data: [
            {
              name: "Profesional",
              y: 0,
            },
          ],
        },
      ],
    },
  }),
  methods: {
    loadServicios_x_profesionales() {
      const date = {
        inicio: moment(this.date, "YYYY-MM")
          .startOf("month")
          .format("YYYY-MM-DD"),
        fin: moment(this.date, "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
      const body = {
        route: "/servicios_x_profesional",
        params: {
          date: JSON.stringify(date),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          const servicios = response.data.data.map((p) => {
            return {
              name: p.profesional,
              y: parseFloat(p.cantidad),
            };
          });
          const total = response.data.data.map((p) => {
            return {
              name: p.profesional,
              y: parseFloat(p.total),
            };
          });

          this.options.series[0].data = servicios;
          this.options.series[1].data = total;
          Highcharts.chart(this._id, this.options);
        }
      });
    },
  },
  created() {
    this.loadServicios_x_profesionales();
  },
  mounted() {
    Highcharts.chart(this._id, this.options);
  },
  watch: {
    date() {
      this.loadServicios_x_profesionales();
      return this.date;
    },
  },
};
</script>
<style scoped>
.chart-prof {
  max-width: 900px;
}
</style>

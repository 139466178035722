<template>
  <div class="graficas mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Gráficas
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text >
                    <facturas-gr :_id="'chart-fact'" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text >
                    <servicios-gr :_id="'chart-sr'" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <productos-gr :_id="'chart-pr'" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <segmento-gr :_id="'chart-seg'"/>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <anual-gr :_id="'chart-anual'"/>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <profesionales-gr :_id="'chart-prof'" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AnualGr from '../../components/graficas/AnualGr.vue';
import FacturasGr from '../../components/graficas/FacturasGr.vue';
import ProductosGr from "../../components/graficas/ProductosGr.vue";
import ProfesionalesGr from '../../components/graficas/ProfesionalesGr.vue';
import SegmentoGr from '../../components/graficas/SegmentoGr.vue';
import ServiciosGr from "../../components/graficas/ServiciosGr.vue";
export default {
  components: { ServiciosGr, ProductosGr, FacturasGr, SegmentoGr, AnualGr, ProfesionalesGr },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
